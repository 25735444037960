import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Empty, Table, Col, Row, Space, Divider } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from "../components/icons/FileIcon";
import toast from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import moment from "moment";
import columnsResumenUsage from "../util/tableConfiguration/tablaResumenUsage";

import TablaSort from "../components/table/tableSort";
import TableSearch from "../components/table/tableSearch";
import TableFilter from "../components/table/tableFilter";
import TablaDatoService from "../components/services/table-service";
import "../index.css";
import { Excel } from "antd-table-saveas-excel";

function Usage() {
  const { authAxios } = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [appList, setAppList] = useState([]);
  // const DateValidation =
  //   localStorage.getItem("fechaSelected") != null
  //     ? localStorage.getItem("fechaSelected")
  //     : new Date();
  // const [startDate, setStartDate] = useState(DateValidation);
  const [startDate, setStartDate] = useState(new Date());
  // const [appSelected, setAppSelected] = useState(
  //   localStorage.getItem("aplicacionSelected") || "selected"
  // );
  const [appSelected, setAppSelected] = useState("selected");
  const [ordenamientosTabla, setOrdenamientosTabla] = useState([]);
  const [filtrosBusqueda, setfiltrosBusqueda] = useState([]);
  const [filtrosFila, setFiltrosFila] = useState([]);
  const history = useHistory();
  // const [datasours, setDataSours] = useState(JSON.parse(localStorage.getItem("usageResumen")));
  const [datasours, setDataSours] = useState([]);
  const [listaRegistros, setListaRegistros] = useState(datasours);

  useEffect(() => {
    const getAplication = async () => {
      try {
        // if (localStorage.getItem("fechaSelected") !== null) {
        //   const date = localStorage.getItem("fechaSelected");
        //   setStartDate(new Date(date));
        // }
        const { data } = await authAxios.get("/simusage/apps");
        setAppList(data);
        setLoading(true);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getAplication();
  }, []);

  useEffect(() => {
    const dataChangeResumen = () => {
      if (appSelected !== "selected") {
        // const appLocalStorage = localStorage.getItem("aplicacionSelected");
        localStorage.setItem("aplicacionSelected", appSelected);
        localStorage.removeItem("usageResumen");
        getDataResumenUsage();
      }
      // const appLocalStorage = localStorage.getItem("aplicacionSelected");
      // if (appSelected !== appLocalStorage) {
      //   localStorage.setItem("aplicacionSelected", appSelected);
      //   localStorage.removeItem("usageResumen");
      //   getDataResumenUsage();
      // } else {
      //   CargarDataStorage();
      // }
    };
    dataChangeResumen();
  }, [appSelected]);

  useEffect(() => {
    const dataChangeResumen = () => {
      if (appSelected !== "selected") {
        localStorage.setItem("fechaSelected", startDate);
        localStorage.removeItem("fechaSelected");
        getDataResumenUsage();
        // const fechaStorage = localStorage.getItem("fechaSelected");
        // if (startDate !== fechaStorage) {
        //   localStorage.setItem("fechaSelected", startDate);
        //   localStorage.removeItem("fechaSelected");
        //   getDataResumenUsage();
        // } else {
        //   CargarDataStorage();
        // }
      }
    };
    dataChangeResumen();
  }, [startDate]);

  if (!authState.profile.hasSimindex) {
    history.push("/");
  }

  const eventoTabla = (evento) => {
    if (evento) {
      let datos = [];
      switch (evento.accion) {
        case "ordenar": {
          const ordenamiento = TablaDatoService.generarOrdenamientoTabla(
            evento,
            ordenamientosTabla
          );
          datos = TablaDatoService.ordenarDatos(
            datasours,
            evento,
            ordenamiento
          );

          setOrdenamientosTabla([...ordenamiento]);
          setListaRegistros([...datos]);

          break;
        }
        case "buscar": {
          const busquedas = TablaDatoService.generarFiltrosBusquedaTabla(
            evento,
            filtrosBusqueda
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            busquedas,
            filtrosFila
          );

          setfiltrosBusqueda([...busquedas]);
          setListaRegistros([...datos]);

          break;
        }
        case "filtrar": {
          const filtros = TablaDatoService.generarFiltrosTabla(
            evento,
            filtrosFila
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            filtrosBusqueda,
            filtros
          );

          setFiltrosFila([...filtros]);
          setListaRegistros([...datos]);
          break;
        }
      }
    }
  };
  const getDataResumenUsage = async () => {
    setLoading(false);
    const fecha = moment(startDate).format("YYYY-MM-01");
    const { data } = await authAxios.get(
      `/simusage/dashboard/${appSelected}/${fecha}`
    );
    data.map((x, i) => {
      i++;
      x.visible = true;
      x.rak = i;
      x.icon =
        Number(x.PercentUseUsersCurrentMonthVSTotal) >
        Number(x.PercentUseUsersPreviousMonthVSTotal)
          ? "sub"
          : "baj";
      return x;
    });
    localStorage.setItem("usageResumen", JSON.stringify(data));
    localStorage.setItem("fechaSelected", startDate);
    setListaRegistros(data);
    setDataSours(data);
    setLoading(true);
  };

  // const CargarDataStorage = () => {
  //   setLoading(false);
  //   setListaRegistros([]);
  //   const DataStorageResumen = JSON.parse(localStorage.getItem("usageResumen"));
  //   setListaRegistros(DataStorageResumen);
  //   setLoading(true);
  // };
  const columns = columnsResumenUsage.map((config, i) => {
    return {
      title: (
        <>
          <Row>
            <Col
              span="24"
              key={config.propiedad}
              align="center"
              className="tablaHeigthUsage"
            >
              {" "}
              {config.etiquetaTraduccion}{" "}
            </Col>
            <Col span="24">
              <Divider>{""}</Divider>
            </Col>
          </Row>

          <Row align="center">
            <Col span="24" justify="space-between" align="center">
              <Space
                size="small"
                justify="space-between"
                align="end"
                direction="horizontal"
              >
                {config.ordenar ? (
                  <TablaSort
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.buscar ? (
                  <TableSearch
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.filtrar ? (
                  <TableFilter
                    propiedadObjeto={config.propiedad}
                    datos={listaRegistros}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
        </>
      ),
      dataIndex: config.propiedad,
      render: config.render,
      key: config.propiedad,
      width: config.width,
      align: "center",
    };
  });
  const columnsExport = columnsResumenUsage.map((config) => {
    return {
      title: config.etiquetaTraduccion,
      dataIndex: config.propiedad,
    };
  });

  const ocultarFilas = (val) => {
    return val.visible ? "" : "hidden";
  };

  const empt = {
    emptyText: (
      <span>
        <Empty></Empty>
      </span>
    ),
  };

  const exportExcel = () => {
    setLoading(false);
    const listadoFIlter = listaRegistros.filter((f) => f.visible === true);
    const excel = new Excel();
    excel
      .setTHeadStyle({ background: "FFFFFF", fontSize: 10 })
      .addSheet("test1")
      .addColumns(columnsExport)
      .addDataSource(listadoFIlter)
      .saveAs("resumenusage.xlsx");
    setLoading(true);
  };

  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="bg-white px-4 py-5 shadow sm:p-6">
            <div className="space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-2 mt-5 sm:col-span-2 md:mt-0">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Aplicación
                  </label>
                  <select
                    key={"selectedApp"}
                    defaultValue={appSelected}
                    onChange={(val) => setAppSelected(val.target.value)}
                    className="bg-gray-30 mb-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:ring-indigo-500block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:ring-indigo-500"
                  >
                    <option key={"selected"} value={"selected"} disabled>
                      Seleccion una aplicación
                    </option>
                    {appList.map((app) => (
                      <option key={app.clave} value={app.clave}>
                        {app.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-6 mt-5 sm:col-span-2 md:mt-0">
                  <label
                    htmlFor="init"
                    className="block mb-2 text-sm font-medium text-gray-700 space-y-6"
                  >
                    Periodo
                  </label>
                  <DatePicker
                    className=" block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </div>
                <div className="col-span-2 mb-12 sm:col-span-2 md:mt-2">
                  <button
                    type="button"
                    onClick={exportExcel}
                    className="flex bottom-0 mt-2 ml-20  border border-white hover:border-indigo-500 focus:ring-4 focus:outline-none focus:ring-indigo-500font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-indigo-500 dark:text-indigo-500 dark:hover:text-white dark:hover:bg-indigo-500 dark:focus:ring-indigo-500"
                  >
                    <Files className="mr-2" />
                    <span className="mt-1">Exportar</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: 400 }}
            >
              <Table
                locale={empt}
                rowKey="Client"
                dataSource={listaRegistros}
                columns={columns}
                rowClassName={ocultarFilas}
                scroll={{ x: "max-content", y: 400 }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Usage;
