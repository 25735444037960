import React, { useState, useEffect, useContext } from "react";
import { Empty, Table, Col, Divider, Row, Space } from "antd";
import { useParams, useHistory } from "react-router-dom";
import RadialGrafica from "../util/graficas/Radial";
import LinealGrafica from "../util/graficas/Lineal";
import toast from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import moment from "moment";
import columnsResumenClient from "../util/tableConfiguration/tablaResumenClient";
import Files from "../components/icons/FileIcon";
import TablaSort from "../components/table/tableSort";
import TableSearch from "../components/table/tableSearch";
import TableFilter from "../components/table/tableFilter";
import TablaDatoService from "../components/services/table-service";
import ChevronsLeftIcon from "../components/icons/Dashboard/ChevronsLeftIcon";
import { Excel } from "antd-table-saveas-excel";

function UsageClient() {
  const { authAxios } = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const { app, id, fecha } = useParams();
  const [loading, setLoading] = useState(false);
  const [ordenamientosTabla, setOrdenamientosTabla] = useState([]);
  const [filtrosBusqueda, setfiltrosBusqueda] = useState([]);
  const [filtrosFila, setFiltrosFila] = useState([]);
  const [filterCliente, setFilter] = useState([]);
  const history = useHistory();
  const [categorias, setCategorias] = useState([]);
  const [valor, setValor] = useState([]);
  const [listaRegistros, setListaRegistros] = useState([]);

  useEffect(() => {
    const getDataClient = async () => {
      try {
        const dataStorage = JSON.parse(localStorage.getItem("usageResumen"));
        localStorage.setItem("idClient", id);
        if (dataStorage) {
          const filters = dataStorage.filter(
            (cliente) => cliente.Client === id
          );
          setFilter(filters);
          const fecha = moment(localStorage.getItem("fechaSelected")).format(
            "YYYY-MM-01"
          );
          const cliente = filters[0].Client;
          const nombreCliente = filters[0].ClientName;
          const url = `/simusage/groupaccessdates/${app}/${fecha}/${cliente}/${nombreCliente}`;
          const { data } = await authAxios.get(url);
          data.forEach((d) => {
            setValor((v) => [...v, Number(d.AccesosDia)]);
            setCategorias((c) => [...c, d.Fecha]);
          });
          const urlTabla = `/simusage/groupaccessusers/${app}/${fecha}/${cliente}/${nombreCliente}`;
          const dataUsuario = await authAxios.get(urlTabla);
          dataUsuario.data.map((d, i) => {
            i++;
            d.visible = true;
            d.rak = i;
            d.icon =
              Number(d.PctMesActual) > Number(d.PctMesAnterior) ? "sub" : "baj";
            return d;
          });
          localStorage.setItem(
            "usageResumenUser",
            JSON.stringify(dataUsuario.data)
          );
          localStorage.setItem("NombreCliente", nombreCliente);
          setListaRegistros(dataUsuario.data);
          setLoading(true);
        }
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getDataClient();
  }, []);

  if (!authState.profile.hasSimindex) {
    history.push("/");
  }

  const eventoTabla = (evento) => {
    if (evento) {
      let datos = [];
      switch (evento.accion) {
        case "ordenar": {
          const ordenamiento = TablaDatoService.generarOrdenamientoTabla(
            evento,
            ordenamientosTabla
          );
          datos = TablaDatoService.ordenarDatos(
            listaRegistros,
            evento,
            ordenamiento
          );

          setOrdenamientosTabla([...ordenamiento]);
          setListaRegistros([...datos]);

          break;
        }
        case "buscar": {
          const busquedas = TablaDatoService.generarFiltrosBusquedaTabla(
            evento,
            filtrosBusqueda
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            busquedas,
            filtrosFila
          );

          setfiltrosBusqueda([...busquedas]);
          setListaRegistros([...datos]);

          break;
        }
        case "filtrar": {
          const filtros = TablaDatoService.generarFiltrosTabla(
            evento,
            filtrosFila
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            filtrosBusqueda,
            filtros
          );

          setFiltrosFila([...filtros]);
          setListaRegistros([...datos]);
          break;
        }
      }
    }
  };

  const empt = {
    emptyText: (
      <span>
        <Empty></Empty>
      </span>
    ),
  };

  const columns = columnsResumenClient.map((config, i) => {
    return {
      title: (
        <>
          <Row>
            <Col span="24" key={config.propiedad} align="center">
              {" "}
              {config.etiquetaTraduccion}{" "}
            </Col>
            <Col span="24">
              <Divider>{""}</Divider>
            </Col>
          </Row>

          <Row align="center">
            <Col span="24" justify="space-between" align="center">
              <Space
                size="small"
                justify="space-between"
                align="end"
                direction="horizontal"
              >
                {config.ordenar ? (
                  <TablaSort
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.buscar ? (
                  <TableSearch
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.filtrar ? (
                  <TableFilter
                    propiedadObjeto={config.propiedad}
                    datos={listaRegistros}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
        </>
      ),
      dataIndex: config.propiedad,
      render: config.render,
      key: config.propiedad,
      width: config.width,
      align: "center",
    };
  });
  const columnsExport = columnsResumenClient.map((config, i) => {
    return {
      title: config.etiquetaTraduccion,
      dataIndex: config.propiedad,
      key: config.propiedad,
    };
  });

  const ocultarFilas = (val) => {
    return val.visible ? "" : "hidden";
  };

  const regresar = () => {
    history.push("/usage/");
  };

  const exportExcel = () => {
    setLoading(false);
    const listadoFIlter = listaRegistros.filter((f) => f.visible === true);
    const excel = new Excel();
    excel
      .setTHeadStyle({ background: "FFFFFF", fontSize: 10 })
      .addSheet("Hoja1")
      .addColumns(columnsExport)
      .addDataSource(listadoFIlter)
      .saveAs("ResumenUsageClient.xlsx");
    setLoading(true);
  };
  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="sticky top-0 mb-2">
          <button
            className="hover:bg-simblue-900  py-2 px-2 rounded-full"
            onClick={regresar}
          >
            <ChevronsLeftIcon className="" />
          </button>
        </div>
        {filterCliente.map((client) => (
          <div
            className="flex flex-auto grid pl-10 gap-2 sm:grid-cols-2 md:grid-cols-4 gap-2 mr-10"
            key={client.Client}
          >
            <div className="max-w-sm rounded overflow-hidden  ml-10">
              <div>
                <div className="mb-2 pr-10 pl-10 bg-simblue-900  text-white text-center">
                  {" "}
                  Cliente: {client.Client}{" "}
                </div>
                <p className="text-gray-900 text-base text-2xl text-center">
                  {client.ClientName}
                </p>
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ml-10">
              <div>
                <div className=" pr-10 pl-10 bg-simblue-900  text-white text-center">
                  % Mes actual
                </div>
                <RadialGrafica
                  valor={client.PercentUseUsersCurrentMonthVSTotal}
                  color={
                    Number(client.PercentUseUsersCurrentMonthVSTotal) >
                    Number(client.PercentUseUsersPreviousMonthVSTotal)
                      ? "#46C844"
                      : "#CE3131"
                  }
                />
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ml-10">
              <div>
                <div className=" pr-10 pl-10 bg-simblue-900  text-white text-center">
                  % Mes anterior
                </div>
                <RadialGrafica
                  valor={client.PercentUseUsersPreviousMonthVSTotal}
                  color={
                    Number(client.PercentUseUsersCurrentMonthVSTotal) <
                    Number(client.PercentUseUsersPreviousMonthVSTotal)
                      ? "#46C844"
                      : "#CE3131"
                  }
                />
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden  ml-10">
              <div>
                <div className="mb-2 pr-10 pl-10 bg-simblue-900  text-white text-center">
                  Licencias
                </div>
                <p className="text-gray-900 text-base text-2xl text-center">
                  {" "}
                  {client.TotalLicenses}{" "}
                </p>
              </div>
              <div>
                <div className="mb-2 pr-10 pl-10 bg-simblue-900  text-white text-center">
                  En uso
                </div>
                <p className="text-gray-900 text-base text-2xl text-center">
                  {client.TotalLicensesCurrentMonth}
                </p>
              </div>
            </div>
          </div>
        ))}

        <div className="flex flex-col mt-10 items-center justify-center w-100">
          <p className="text-xl mb-3">Accesos Totales</p>
          <LinealGrafica valor={valor} categorias={categorias} />
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 ">
          <div className=" px-4 py-5 shadow sm:p-6 mb-10">
            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <div className="flex flex-row items-center justify-center">
                <p className="text-xl mb-3">
                  Accesos a {app} en el mes {fecha}
                </p>
                <div className="items-right justify-right">
                  <button
                    type="button"
                    onClick={exportExcel}
                    loading={!loading}
                    className="flex bottom-0 mt-2 ml-20  border border-white hover:border-indigo-500 focus:ring-4 focus:outline-none focus:ring-indigo-500font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-indigo-500 dark:text-indigo-500 dark:hover:text-white dark:hover:bg-indigo-500 dark:focus:ring-indigo-500"
                  >
                    <Files className="mr-2" />
                    <span className="mt-1">Exportar</span>
                  </button>
                </div>
              </div>

              <Table
                locale={empt}
                rowKey="Email"
                dataSource={listaRegistros}
                columns={columns}
                rowClassName={ocultarFilas}
                scroll={{ x: "max-content", y: 400 }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default UsageClient;
